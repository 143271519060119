import React, { useState } from "react"

import Layout from "../../../components/Layout/secondaryPage"

import { NewVerifySMS } from "../../../components/NewVerifySMS"
import ProgressBarGreen from "../../../components/ProgressBarGreen"
import FormContainer from "../../../components/FormContainer"

export default function VerifyPage() {
  const [widthGreenBar, setWidthGreenBar] = useState(50)

  return (
    <Layout>
      <FormContainer>
        <ProgressBarGreen steps={2} widthAnimationBar={90} widthGreenBar={widthGreenBar} />
        <NewVerifySMS setWidthGreenBar={setWidthGreenBar} />
      </FormContainer>
    </Layout>
  )
}
